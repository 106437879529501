.app-container {
  text-align: center;
  margin: 0 5px 0 5px;
  display: flex;
  justify-content: center;

  .main-content {
    min-height: calc(100vh - 200px);
  }
  footer {
    display: flex;
    justify-content: center;
    margin: 10px 0 10px 0;
    button,
    a {
      text-transform: none;
      font-size: 1rem;
    }
  }
}

// @media (prefers-reduced-motion: no-preference) {
//   .app-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

.MuiContainer-root {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.app-logo {
  width: 50px;
  height: 50px;
}

.app-header {
  text-transform: uppercase;
  display: flex;
  min-height: 10vh;
  font-size: 15px;
  color: white;
  gap: 10px;
  //margin-bottom: 30px;

  align-items: center;
  .app-logo {
    margin: 10px 5px;
  }
}

.header-back-button {
  display: flex;
}

h1 {
  margin: 5px;
}

.App-link {
  color: #61dafb;
}

.info-message {
  color: white;
  margin: 10px 0;
  border: 1px solid green;
  padding: 20px;
  border-radius: 5px;
}
.multiline {
  white-space: pre-wrap;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
