.current-game-container {
  padding-bottom: 30px;
  .date-buy-in-container {
    display: flex;
    gap: 10px;
  }
  .players-heading-container {
    display: flex;
    // gap: 10px;
    justify-content: space-between;
    .add-player-btn {
      color: #26da1f;
    }
  }
  .unsettled-amount {
    margin: 10px 0;
  }
  .player-section {
    display: flex;
    gap: 15px;
    .player-row {
      display: flex;
      gap: 5px;
      align-items: center;
      //border: 1px solid green;
      padding: 10px 5px;
      border-radius: 5px;
      margin: 10px 0;
      background-color: #5939c1; //#8f3df5;
      width: 100%;
      &.final-amnt-added {
        background-color: #187b4e;
        //border: 1px solid #5939c1;
      }
      .player-name-container {
        //margin-right: auto;
        font-size: 1rem;
        font-weight: bold;
        color: white;
        //uppercase
        text-transform: uppercase;
        width: 20%;
        flex: 0 0 75px;
        .player-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }
      }
      .players-btn {
        padding: 0;
        .players-btn-icon {
          margin-left: auto;
          margin-right: 0;
          color: peachpuff;
        }
      }
      .dollar-amnt {
        min-width: 45px;
        font-size: 1rem;
        font-weight: bold;
      }
      .edit-player-btn {
        margin-left: auto;
      }
    }
  }
  .delete-yes-btn {
    color: white;
  }
  .delete-player-btn {
    margin: 20px 0;
  }
}
