.add-player-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 5px;
  max-width: 100%;
  width: 300px;

  .final-payout-container {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 10px 0;
    .final-payout-label {
      font-size: 1rem;
    }
    .final-payout-amnt {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }
  button[type="submit"] {
    background: #177faf; //rgb(86 8 133 / 69%);
    color: white;
  }
}
