.results-container {
  .title-area {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  h2 {
    position: absolute;
    margin-top: 4px;
  }
  .game-details {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
  }

  .ribbon {
    max-width: 100%;
  }
  .player-cards {
    .player-content {
      display: flex;
      gap: 5px;
      align-items: center;
      border: 1px solid green;
      padding: 10px;
      border-radius: 5px;
      margin: 10px 0;
      background-color: #8f3df5;
      font-size: 1rem;
      font-weight: bold;
      color: white;
      text-transform: uppercase;
      .player-name-container {
        display: flex;
        width: 50%;
        flex: 0 0 145px;
      }
      .player-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
      .cash-value {
        font-size: 1.1rem;
        font-weight: bold;
      }
      .player-rank {
        margin-left: auto;
      }
    }
  }
}
