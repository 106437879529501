.confirmation-dialog {
  .MuiBackdrop-root {
    background: transparent !important;
  }
  .dialog-title {
    margin-bottom: 10px;
  }
  &.error {
    .MuiDialog-paper {
      background-color: white;
      color: rgb(84, 84, 84);
    }
    .dialog-title {
      color: #fff;
      background: #d12356;
    }

    .dialog-buttons {
      .cancel-btn {
        // background-color: #d12356;
        color: rgb(84, 84, 84);
        border: 1px solid rgb(84, 84, 84);

        &:hover {
          background-color: #1e1e1e;
          color: #fff;
        }
      }
      .primary-btn {
        background-color: #d12356;
        color: white;
        &:hover {
          background-color: #1e1e1e;
          color: #fff;
        }
      }
    }
  }
  &.video {
    width: 100%;
  }
  &.info {
    .MuiDialog-paper {
      background-color: white;
      color: rgb(84, 84, 84);
    }
    .dialog-title {
      color: #fff;
      background: rgb(67, 87, 173);
    }

    .dialog-buttons {
      .cancel-btn {
        // background-color: #d12356;
        color: rgb(84, 84, 84);
        border: 1px solid rgb(84, 84, 84);

        &:hover {
          background-color: #1e1e1e;
          color: #fff;
        }
      }
      .primary-btn {
        background-color: rgb(67, 87, 173);
        color: white;
        &:hover {
          background-color: rgb(67, 87, 173);
          color: #fff;
        }
      }
    }
  }
  //background-color: #1e1e1e;
  color: #fff;

  .dialog-title {
    color: #fff;
  }

  .dialog-message {
    color: #fff;
  }

  .dialog-buttons {
    display: flex;
    justify-content: center;
    button {
      background-color: #fff;
      color: #1e1e1e;

      &:hover {
        background-color: #1e1e1e;
        color: #fff;
      }
    }
  }
}
