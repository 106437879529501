.flex-card-container {
  display: flex;
  flex-direction: row;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .flex-card-container {
    flex-direction: column;
  }
}

.card-container {
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  margin: 20px 10px;
  .text-content {
    margin: 15px;
    font-size: 1rem;
    div {
      margin: 5px 0;
      //uppercase
      font-size: 0.9rem;
      font-weight: normal;
    }
    .total-buyin {
      font-size: 0.9rem;
      font-weight: normal;
    }
    .date {
      //text-transform: uppercase;
      font-size: 1.1rem;
    }
  }
  img {
    margin-left: auto;
    margin-right: 0;
    width: 64px;
    height: 64px;
  }
}
