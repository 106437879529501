.start-game-btn {
  width: 100%;
  height: 50px;
  margin: 20px 0 !important;
  font-size: 1.1rem !important;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  background-color: #1f9323 !important; // #008f1f !important; //
  color: #fff !important;
  &:hover {
    background-color: #008f1f;
  }
}
